import { P as n, g as ne, a as te, s as J, u as oe, b as H, d as re, m as Te, h as pe, n as fe } from "./DefaultPropsProvider.js";
import * as l from "react";
import { c as se, u as L, s as be, T as qe, r as Ue, g as me } from "./utils.js";
import { r as _e, m as Ke, a as We, e as He } from "./resolveComponentProps.js";
import { jsxs as Re, jsx as $ } from "react/jsx-runtime";
import { u as xe } from "./useTheme.js";
import * as ze from "react-dom";
function Ye(e) {
  const {
    prototype: t = {}
  } = e;
  return !!t.isReactComponent;
}
function Pe(e, t, r, o, i) {
  const s = e[t], a = i || t;
  if (s == null || // When server-side rendering React doesn't warn either.
  // This is not an accurate check for SSR.
  // This is only in place for Emotion compat.
  // TODO: Revisit once https://github.com/facebook/react/issues/20047 is resolved.
  typeof window > "u")
    return null;
  let c;
  const d = s.type;
  return typeof d == "function" && !Ye(d) && (c = "Did you accidentally use a plain function component for an element instead?"), c !== void 0 ? new Error(`Invalid ${o} \`${a}\` supplied to \`${r}\`. Expected an element that can hold a ref. ${c} For more information see https://mui.com/r/caveat-with-refs-guide`) : null;
}
const we = se(n.element, Pe);
we.isRequired = se(n.element.isRequired, Pe);
const ie = we, Ge = "exact-prop: ​";
function Ne(e) {
  return process.env.NODE_ENV === "production" ? e : {
    ...e,
    [Ge]: (t) => {
      const r = Object.keys(t).filter((o) => !e.hasOwnProperty(o));
      return r.length > 0 ? new Error(`The following props are not supported: ${r.map((o) => `\`${o}\``).join(", ")}. Please remove them.`) : null;
    }
  };
}
function ke(e, t, r, o, i) {
  if (process.env.NODE_ENV === "production")
    return null;
  const s = e[t], a = i || t;
  return s == null ? null : s && s.nodeType !== 1 ? new Error(`Invalid ${o} \`${a}\` supplied to \`${r}\`. Expected an HTMLElement.`) : null;
}
const Xe = n.oneOfType([n.func, n.object]), An = Xe;
function he(...e) {
  return e.reduce((t, r) => r == null ? t : function(...i) {
    t.apply(this, i), r.apply(this, i);
  }, () => {
  });
}
function B(e) {
  return e && e.ownerDocument || document;
}
function z(e) {
  return B(e).defaultView || window;
}
const Je = typeof window < "u" ? l.useLayoutEffect : l.useEffect, Z = Je;
function ye(e) {
  const t = l.useRef(e);
  return Z(() => {
    t.current = e;
  }), l.useRef((...r) => (
    // @ts-expect-error hide `this`
    (0, t.current)(...r)
  )).current;
}
function Qe(e = window) {
  const t = e.document.documentElement.clientWidth;
  return e.innerWidth - t;
}
function Ze(e) {
  const t = typeof e;
  switch (t) {
    case "number":
      return Number.isNaN(e) ? "NaN" : Number.isFinite(e) ? e !== Math.floor(e) ? "float" : "number" : "Infinity";
    case "object":
      return e === null ? "null" : e.constructor.name;
    default:
      return t;
  }
}
function Ce(e, t, r, o) {
  const i = e[t];
  if (i == null || !Number.isInteger(i)) {
    const s = Ze(i);
    return new RangeError(`Invalid ${o} \`${t}\` of type \`${s}\` supplied to \`${r}\`, expected \`integer\`.`);
  }
  return null;
}
function Oe(e, t, ...r) {
  return e[t] === void 0 ? null : Ce(e, t, ...r);
}
function ee() {
  return null;
}
Oe.isRequired = Ce;
ee.isRequired = ee;
const en = process.env.NODE_ENV === "production" ? ee : Oe;
function ae(e) {
  var t;
  return parseInt(l.version, 10) >= 19 ? ((t = e == null ? void 0 : e.props) == null ? void 0 : t.ref) || null : (e == null ? void 0 : e.ref) || null;
}
function nn(e) {
  const t = B(e);
  return t.body === e ? z(e).innerWidth > t.documentElement.clientWidth : e.scrollHeight > e.clientHeight;
}
function U(e, t) {
  t ? e.setAttribute("aria-hidden", "true") : e.removeAttribute("aria-hidden");
}
function Ee(e) {
  return parseInt(z(e).getComputedStyle(e).paddingRight, 10) || 0;
}
function tn(e) {
  const r = ["TEMPLATE", "SCRIPT", "STYLE", "LINK", "MAP", "META", "NOSCRIPT", "PICTURE", "COL", "COLGROUP", "PARAM", "SLOT", "SOURCE", "TRACK"].includes(e.tagName), o = e.tagName === "INPUT" && e.getAttribute("type") === "hidden";
  return r || o;
}
function ve(e, t, r, o, i) {
  const s = [t, r, ...o];
  [].forEach.call(e.children, (a) => {
    const c = !s.includes(a), d = !tn(a);
    c && d && U(a, i);
  });
}
function Q(e, t) {
  let r = -1;
  return e.some((o, i) => t(o) ? (r = i, !0) : !1), r;
}
function on(e, t) {
  const r = [], o = e.container;
  if (!t.disableScrollLock) {
    if (nn(o)) {
      const a = Qe(z(o));
      r.push({
        value: o.style.paddingRight,
        property: "padding-right",
        el: o
      }), o.style.paddingRight = `${Ee(o) + a}px`;
      const c = B(o).querySelectorAll(".mui-fixed");
      [].forEach.call(c, (d) => {
        r.push({
          value: d.style.paddingRight,
          property: "padding-right",
          el: d
        }), d.style.paddingRight = `${Ee(d) + a}px`;
      });
    }
    let s;
    if (o.parentNode instanceof DocumentFragment)
      s = B(o).body;
    else {
      const a = o.parentElement, c = z(o);
      s = (a == null ? void 0 : a.nodeName) === "HTML" && c.getComputedStyle(a).overflowY === "scroll" ? a : o;
    }
    r.push({
      value: s.style.overflow,
      property: "overflow",
      el: s
    }, {
      value: s.style.overflowX,
      property: "overflow-x",
      el: s
    }, {
      value: s.style.overflowY,
      property: "overflow-y",
      el: s
    }), s.style.overflow = "hidden";
  }
  return () => {
    r.forEach(({
      value: s,
      el: a,
      property: c
    }) => {
      s ? a.style.setProperty(c, s) : a.style.removeProperty(c);
    });
  };
}
function rn(e) {
  const t = [];
  return [].forEach.call(e.children, (r) => {
    r.getAttribute("aria-hidden") === "true" && t.push(r);
  }), t;
}
class sn {
  constructor() {
    this.modals = [], this.containers = [];
  }
  add(t, r) {
    let o = this.modals.indexOf(t);
    if (o !== -1)
      return o;
    o = this.modals.length, this.modals.push(t), t.modalRef && U(t.modalRef, !1);
    const i = rn(r);
    ve(r, t.mount, t.modalRef, i, !0);
    const s = Q(this.containers, (a) => a.container === r);
    return s !== -1 ? (this.containers[s].modals.push(t), o) : (this.containers.push({
      modals: [t],
      container: r,
      restore: null,
      hiddenSiblings: i
    }), o);
  }
  mount(t, r) {
    const o = Q(this.containers, (s) => s.modals.includes(t)), i = this.containers[o];
    i.restore || (i.restore = on(i, r));
  }
  remove(t, r = !0) {
    const o = this.modals.indexOf(t);
    if (o === -1)
      return o;
    const i = Q(this.containers, (a) => a.modals.includes(t)), s = this.containers[i];
    if (s.modals.splice(s.modals.indexOf(t), 1), this.modals.splice(o, 1), s.modals.length === 0)
      s.restore && s.restore(), t.modalRef && U(t.modalRef, r), ve(s.container, t.mount, t.modalRef, s.hiddenSiblings, !1), this.containers.splice(i, 1);
    else {
      const a = s.modals[s.modals.length - 1];
      a.modalRef && U(a.modalRef, !1);
    }
    return o;
  }
  isTopModal(t) {
    return this.modals.length > 0 && this.modals[this.modals.length - 1] === t;
  }
}
const an = ["input", "select", "textarea", "a[href]", "button", "[tabindex]", "audio[controls]", "video[controls]", '[contenteditable]:not([contenteditable="false"])'].join(",");
function cn(e) {
  const t = parseInt(e.getAttribute("tabindex") || "", 10);
  return Number.isNaN(t) ? e.contentEditable === "true" || (e.nodeName === "AUDIO" || e.nodeName === "VIDEO" || e.nodeName === "DETAILS") && e.getAttribute("tabindex") === null ? 0 : e.tabIndex : t;
}
function ln(e) {
  if (e.tagName !== "INPUT" || e.type !== "radio" || !e.name)
    return !1;
  const t = (o) => e.ownerDocument.querySelector(`input[type="radio"]${o}`);
  let r = t(`[name="${e.name}"]:checked`);
  return r || (r = t(`[name="${e.name}"]`)), r !== e;
}
function un(e) {
  return !(e.disabled || e.tagName === "INPUT" && e.type === "hidden" || ln(e));
}
function dn(e) {
  const t = [], r = [];
  return Array.from(e.querySelectorAll(an)).forEach((o, i) => {
    const s = cn(o);
    s === -1 || !un(o) || (s === 0 ? t.push(o) : r.push({
      documentOrder: i,
      tabIndex: s,
      node: o
    }));
  }), r.sort((o, i) => o.tabIndex === i.tabIndex ? o.documentOrder - i.documentOrder : o.tabIndex - i.tabIndex).map((o) => o.node).concat(t);
}
function pn() {
  return !0;
}
function Y(e) {
  const {
    children: t,
    disableAutoFocus: r = !1,
    disableEnforceFocus: o = !1,
    disableRestoreFocus: i = !1,
    getTabbable: s = dn,
    isEnabled: a = pn,
    open: c
  } = e, d = l.useRef(!1), h = l.useRef(null), v = l.useRef(null), y = l.useRef(null), T = l.useRef(null), m = l.useRef(!1), b = l.useRef(null), k = L(ae(t), b), R = l.useRef(null);
  l.useEffect(() => {
    !c || !b.current || (m.current = !r);
  }, [r, c]), l.useEffect(() => {
    if (!c || !b.current)
      return;
    const u = B(b.current);
    return b.current.contains(u.activeElement) || (b.current.hasAttribute("tabIndex") || (process.env.NODE_ENV !== "production" && console.error(["MUI: The modal content node does not accept focus.", 'For the benefit of assistive technologies, the tabIndex of the node is being set to "-1".'].join(`
`)), b.current.setAttribute("tabIndex", "-1")), m.current && b.current.focus()), () => {
      i || (y.current && y.current.focus && (d.current = !0, y.current.focus()), y.current = null);
    };
  }, [c]), l.useEffect(() => {
    if (!c || !b.current)
      return;
    const u = B(b.current), E = (N) => {
      R.current = N, !(o || !a() || N.key !== "Tab") && u.activeElement === b.current && N.shiftKey && (d.current = !0, v.current && v.current.focus());
    }, C = () => {
      var S, M;
      const N = b.current;
      if (N === null)
        return;
      if (!u.hasFocus() || !a() || d.current) {
        d.current = !1;
        return;
      }
      if (N.contains(u.activeElement) || o && u.activeElement !== h.current && u.activeElement !== v.current)
        return;
      if (u.activeElement !== T.current)
        T.current = null;
      else if (T.current !== null)
        return;
      if (!m.current)
        return;
      let O = [];
      if ((u.activeElement === h.current || u.activeElement === v.current) && (O = s(b.current)), O.length > 0) {
        const D = !!((S = R.current) != null && S.shiftKey && ((M = R.current) == null ? void 0 : M.key) === "Tab"), A = O[0], V = O[O.length - 1];
        typeof A != "string" && typeof V != "string" && (D ? V.focus() : A.focus());
      } else
        N.focus();
    };
    u.addEventListener("focusin", C), u.addEventListener("keydown", E, !0);
    const w = setInterval(() => {
      u.activeElement && u.activeElement.tagName === "BODY" && C();
    }, 50);
    return () => {
      clearInterval(w), u.removeEventListener("focusin", C), u.removeEventListener("keydown", E, !0);
    };
  }, [r, o, i, a, c, s]);
  const x = (u) => {
    y.current === null && (y.current = u.relatedTarget), m.current = !0, T.current = u.target;
    const E = t.props.onFocus;
    E && E(u);
  }, P = (u) => {
    y.current === null && (y.current = u.relatedTarget), m.current = !0;
  };
  return /* @__PURE__ */ Re(l.Fragment, {
    children: [/* @__PURE__ */ $("div", {
      tabIndex: c ? 0 : -1,
      onFocus: P,
      ref: h,
      "data-testid": "sentinelStart"
    }), /* @__PURE__ */ l.cloneElement(t, {
      ref: k,
      onFocus: x
    }), /* @__PURE__ */ $("div", {
      tabIndex: c ? 0 : -1,
      onFocus: P,
      ref: v,
      "data-testid": "sentinelEnd"
    })]
  });
}
process.env.NODE_ENV !== "production" && (Y.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │ To update them, edit the TypeScript types and run `pnpm proptypes`. │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * A single child content element.
   */
  children: ie,
  /**
   * If `true`, the focus trap will not automatically shift focus to itself when it opens, and
   * replace it to the last focused element when it closes.
   * This also works correctly with any focus trap children that have the `disableAutoFocus` prop.
   *
   * Generally this should never be set to `true` as it makes the focus trap less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableAutoFocus: n.bool,
  /**
   * If `true`, the focus trap will not prevent focus from leaving the focus trap while open.
   *
   * Generally this should never be set to `true` as it makes the focus trap less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableEnforceFocus: n.bool,
  /**
   * If `true`, the focus trap will not restore focus to previously focused element once
   * focus trap is hidden or unmounted.
   * @default false
   */
  disableRestoreFocus: n.bool,
  /**
   * Returns an array of ordered tabbable nodes (i.e. in tab order) within the root.
   * For instance, you can provide the "tabbable" npm dependency.
   * @param {HTMLElement} root
   */
  getTabbable: n.func,
  /**
   * This prop extends the `open` prop.
   * It allows to toggle the open state without having to wait for a rerender when changing the `open` prop.
   * This prop should be memoized.
   * It can be used to support multiple focus trap mounted at the same time.
   * @default function defaultIsEnabled(): boolean {
   *   return true;
   * }
   */
  isEnabled: n.func,
  /**
   * If `true`, focus is locked.
   */
  open: n.bool.isRequired
});
process.env.NODE_ENV !== "production" && (Y["propTypes"] = Ne(Y.propTypes));
function fn(e) {
  return typeof e == "function" ? e() : e;
}
const G = /* @__PURE__ */ l.forwardRef(function(t, r) {
  const {
    children: o,
    container: i,
    disablePortal: s = !1
  } = t, [a, c] = l.useState(null), d = L(/* @__PURE__ */ l.isValidElement(o) ? ae(o) : null, r);
  if (Z(() => {
    s || c(fn(i) || document.body);
  }, [i, s]), Z(() => {
    if (a && !s)
      return be(r, a), () => {
        be(r, null);
      };
  }, [r, a, s]), s) {
    if (/* @__PURE__ */ l.isValidElement(o)) {
      const h = {
        ref: d
      };
      return /* @__PURE__ */ l.cloneElement(o, h);
    }
    return o;
  }
  return a && /* @__PURE__ */ ze.createPortal(o, a);
});
process.env.NODE_ENV !== "production" && (G.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │ To update them, edit the TypeScript types and run `pnpm proptypes`. │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The children to render into the `container`.
   */
  children: n.node,
  /**
   * An HTML element or function that returns one.
   * The `container` will have the portal children appended to it.
   *
   * You can also provide a callback, which is called in a React layout effect.
   * This lets you set the container from a ref, and also makes server-side rendering possible.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: n.oneOfType([ke, n.func]),
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: n.bool
});
process.env.NODE_ENV !== "production" && (G["propTypes"] = Ne(G.propTypes));
const bn = G;
function X(e, t) {
  const {
    className: r,
    elementType: o,
    ownerState: i,
    externalForwardedProps: s,
    internalForwardedProps: a,
    shouldForwardComponentProp: c = !1,
    ...d
  } = t, {
    component: h,
    slots: v = {
      [e]: void 0
    },
    slotProps: y = {
      [e]: void 0
    },
    ...T
  } = s, m = v[e] || o, b = _e(y[e], i), {
    props: {
      component: k,
      ...R
    },
    internalRef: x
  } = Ke({
    className: r,
    ...d,
    externalForwardedProps: e === "root" ? T : void 0,
    externalSlotProps: b
  }), P = L(x, b == null ? void 0 : b.ref, t.ref), u = e === "root" ? k || h : k, E = We(m, {
    ...e === "root" && !h && !v[e] && a,
    ...e !== "root" && !v[e] && a,
    ...R,
    ...u && !c && {
      as: u
    },
    ...u && c && {
      component: u
    },
    ref: P
  }, i);
  return [m, E];
}
const mn = {
  entering: {
    opacity: 1
  },
  entered: {
    opacity: 1
  }
}, Se = /* @__PURE__ */ l.forwardRef(function(t, r) {
  const o = xe(), i = {
    enter: o.transitions.duration.enteringScreen,
    exit: o.transitions.duration.leavingScreen
  }, {
    addEndListener: s,
    appear: a = !0,
    children: c,
    easing: d,
    in: h,
    onEnter: v,
    onEntered: y,
    onEntering: T,
    onExit: m,
    onExited: b,
    onExiting: k,
    style: R,
    timeout: x = i,
    // eslint-disable-next-line react/prop-types
    TransitionComponent: P = qe,
    ...u
  } = t, E = l.useRef(null), C = L(E, ae(c), r), w = (g) => (p) => {
    if (g) {
      const f = E.current;
      p === void 0 ? g(f) : g(f, p);
    }
  }, N = w(T), O = w((g, p) => {
    Ue(g);
    const f = me({
      style: R,
      timeout: x,
      easing: d
    }, {
      mode: "enter"
    });
    g.style.webkitTransition = o.transitions.create("opacity", f), g.style.transition = o.transitions.create("opacity", f), v && v(g, p);
  }), S = w(y), M = w(k), D = w((g) => {
    const p = me({
      style: R,
      timeout: x,
      easing: d
    }, {
      mode: "exit"
    });
    g.style.webkitTransition = o.transitions.create("opacity", p), g.style.transition = o.transitions.create("opacity", p), m && m(g);
  }), A = w(b);
  return /* @__PURE__ */ $(P, {
    appear: a,
    in: h,
    nodeRef: E,
    onEnter: O,
    onEntered: S,
    onEntering: N,
    onExit: D,
    onExited: A,
    onExiting: M,
    addEndListener: (g) => {
      s && s(E.current, g);
    },
    timeout: x,
    ...u,
    children: (g, {
      ownerState: p,
      ...f
    }) => /* @__PURE__ */ l.cloneElement(c, {
      style: {
        opacity: 0,
        visibility: g === "exited" && !h ? "hidden" : void 0,
        ...mn[g],
        ...R,
        ...c.props.style
      },
      ref: C,
      ...f
    })
  });
});
process.env.NODE_ENV !== "production" && (Se.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * Add a custom transition end trigger. Called with the transitioning DOM
   * node and a done callback. Allows for more fine grained transition end
   * logic. Note: Timeouts are still used as a fallback if provided.
   */
  addEndListener: n.func,
  /**
   * Perform the enter transition when it first mounts if `in` is also `true`.
   * Set this to `false` to disable this behavior.
   * @default true
   */
  appear: n.bool,
  /**
   * A single child content element.
   */
  children: ie.isRequired,
  /**
   * The transition timing function.
   * You may specify a single easing or a object containing enter and exit values.
   */
  easing: n.oneOfType([n.shape({
    enter: n.string,
    exit: n.string
  }), n.string]),
  /**
   * If `true`, the component will transition in.
   */
  in: n.bool,
  /**
   * @ignore
   */
  onEnter: n.func,
  /**
   * @ignore
   */
  onEntered: n.func,
  /**
   * @ignore
   */
  onEntering: n.func,
  /**
   * @ignore
   */
  onExit: n.func,
  /**
   * @ignore
   */
  onExited: n.func,
  /**
   * @ignore
   */
  onExiting: n.func,
  /**
   * @ignore
   */
  style: n.object,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   * @default {
   *   enter: theme.transitions.duration.enteringScreen,
   *   exit: theme.transitions.duration.leavingScreen,
   * }
   */
  timeout: n.oneOfType([n.number, n.shape({
    appear: n.number,
    enter: n.number,
    exit: n.number
  })])
});
const hn = Se;
function yn(e) {
  return ne("MuiBackdrop", e);
}
te("MuiBackdrop", ["root", "invisible"]);
const En = (e) => {
  const {
    classes: t,
    invisible: r
  } = e;
  return re({
    root: ["root", r && "invisible"]
  }, yn, t);
}, vn = J("div", {
  name: "MuiBackdrop",
  slot: "Root",
  overridesResolver: (e, t) => {
    const {
      ownerState: r
    } = e;
    return [t.root, r.invisible && t.invisible];
  }
})({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  WebkitTapHighlightColor: "transparent",
  variants: [{
    props: {
      invisible: !0
    },
    style: {
      backgroundColor: "transparent"
    }
  }]
}), Ie = /* @__PURE__ */ l.forwardRef(function(t, r) {
  const o = oe({
    props: t,
    name: "MuiBackdrop"
  }), {
    children: i,
    className: s,
    component: a = "div",
    invisible: c = !1,
    open: d,
    components: h = {},
    componentsProps: v = {},
    slotProps: y = {},
    slots: T = {},
    TransitionComponent: m,
    transitionDuration: b,
    ...k
  } = o, R = {
    ...o,
    component: a,
    invisible: c
  }, x = En(R), P = {
    transition: m,
    root: h.Root,
    ...T
  }, u = {
    ...v,
    ...y
  }, E = {
    slots: P,
    slotProps: u
  }, [C, w] = X("root", {
    elementType: vn,
    externalForwardedProps: E,
    className: H(x.root, s),
    ownerState: R
  }), [N, O] = X("transition", {
    elementType: hn,
    externalForwardedProps: E,
    ownerState: R
  });
  return /* @__PURE__ */ $(N, {
    in: d,
    timeout: b,
    ...k,
    ...O,
    children: /* @__PURE__ */ $(C, {
      "aria-hidden": !0,
      ...w,
      classes: x,
      ref: r,
      children: i
    })
  });
});
process.env.NODE_ENV !== "production" && (Ie.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The content of the component.
   */
  children: n.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: n.object,
  /**
   * @ignore
   */
  className: n.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: n.elementType,
  /**
   * The components used for each slot inside.
   *
   * @deprecated Use the `slots` prop instead. This prop will be removed in v7. See [Migrating from deprecated APIs](https://mui.com/material-ui/migration/migrating-from-deprecated-apis/) for more details.
   *
   * @default {}
   */
  components: n.shape({
    Root: n.elementType
  }),
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * @deprecated Use the `slotProps` prop instead. This prop will be removed in v7. See [Migrating from deprecated APIs](https://mui.com/material-ui/migration/migrating-from-deprecated-apis/) for more details.
   *
   * @default {}
   */
  componentsProps: n.shape({
    root: n.object
  }),
  /**
   * If `true`, the backdrop is invisible.
   * It can be used when rendering a popover or a custom select component.
   * @default false
   */
  invisible: n.bool,
  /**
   * If `true`, the component is shown.
   */
  open: n.bool.isRequired,
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps: n.shape({
    root: n.oneOfType([n.func, n.object]),
    transition: n.oneOfType([n.func, n.object])
  }),
  /**
   * The components used for each slot inside.
   * @default {}
   */
  slots: n.shape({
    root: n.elementType,
    transition: n.elementType
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: n.oneOfType([n.arrayOf(n.oneOfType([n.func, n.object, n.bool])), n.func, n.object]),
  /**
   * The component used for the transition.
   * [Follow this guide](https://mui.com/material-ui/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
   * @default Fade
   * @deprecated Use `slots.transition` instead. This prop will be removed in v7. See [Migrating from deprecated APIs](/material-ui/migration/migrating-from-deprecated-apis/) for more details.
   */
  TransitionComponent: n.elementType,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration: n.oneOfType([n.number, n.shape({
    appear: n.number,
    enter: n.number,
    exit: n.number
  })])
});
const gn = Ie;
function Tn(e) {
  return typeof e == "function" ? e() : e;
}
function Rn(e) {
  return e ? e.props.hasOwnProperty("in") : !1;
}
const ge = () => {
}, W = new sn();
function xn(e) {
  const {
    container: t,
    disableEscapeKeyDown: r = !1,
    disableScrollLock: o = !1,
    closeAfterTransition: i = !1,
    onTransitionEnter: s,
    onTransitionExited: a,
    children: c,
    onClose: d,
    open: h,
    rootRef: v
  } = e, y = l.useRef({}), T = l.useRef(null), m = l.useRef(null), b = L(m, v), [k, R] = l.useState(!h), x = Rn(c);
  let P = !0;
  (e["aria-hidden"] === "false" || e["aria-hidden"] === !1) && (P = !1);
  const u = () => B(T.current), E = () => (y.current.modalRef = m.current, y.current.mount = T.current, y.current), C = () => {
    W.mount(E(), {
      disableScrollLock: o
    }), m.current && (m.current.scrollTop = 0);
  }, w = ye(() => {
    const p = Tn(t) || u().body;
    W.add(E(), p), m.current && C();
  }), N = () => W.isTopModal(E()), O = ye((p) => {
    T.current = p, p && (h && N() ? C() : m.current && U(m.current, P));
  }), S = l.useCallback(() => {
    W.remove(E(), P);
  }, [P]);
  l.useEffect(() => () => {
    S();
  }, [S]), l.useEffect(() => {
    h ? w() : (!x || !i) && S();
  }, [h, S, x, i, w]);
  const M = (p) => (f) => {
    var I;
    (I = p.onKeyDown) == null || I.call(p, f), !(f.key !== "Escape" || f.which === 229 || // Wait until IME is settled.
    !N()) && (r || (f.stopPropagation(), d && d(f, "escapeKeyDown")));
  }, D = (p) => (f) => {
    var I;
    (I = p.onClick) == null || I.call(p, f), f.target === f.currentTarget && d && d(f, "backdropClick");
  };
  return {
    getRootProps: (p = {}) => {
      const f = He(e);
      delete f.onTransitionEnter, delete f.onTransitionExited;
      const I = {
        ...f,
        ...p
      };
      return {
        /*
         * Marking an element with the role presentation indicates to assistive technology
         * that this element should be ignored; it exists to support the web application and
         * is not meant for humans to interact with directly.
         * https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-static-element-interactions.md
         */
        role: "presentation",
        ...I,
        onKeyDown: M(I),
        ref: b
      };
    },
    getBackdropProps: (p = {}) => {
      const f = p;
      return {
        "aria-hidden": !0,
        ...f,
        onClick: D(f),
        open: h
      };
    },
    getTransitionProps: () => {
      const p = () => {
        R(!1), s && s();
      }, f = () => {
        R(!0), a && a(), i && S();
      };
      return {
        onEnter: he(p, (c == null ? void 0 : c.props.onEnter) ?? ge),
        onExited: he(f, (c == null ? void 0 : c.props.onExited) ?? ge)
      };
    },
    rootRef: b,
    portalRef: O,
    isTopModal: N,
    exited: k,
    hasTransition: x
  };
}
function Pn(e) {
  return ne("MuiModal", e);
}
te("MuiModal", ["root", "hidden", "backdrop"]);
const wn = (e) => {
  const {
    open: t,
    exited: r,
    classes: o
  } = e;
  return re({
    root: ["root", !t && r && "hidden"],
    backdrop: ["backdrop"]
  }, Pn, o);
}, Nn = J("div", {
  name: "MuiModal",
  slot: "Root",
  overridesResolver: (e, t) => {
    const {
      ownerState: r
    } = e;
    return [t.root, !r.open && r.exited && t.hidden];
  }
})(Te(({
  theme: e
}) => ({
  position: "fixed",
  zIndex: (e.vars || e).zIndex.modal,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  variants: [{
    props: ({
      ownerState: t
    }) => !t.open && t.exited,
    style: {
      visibility: "hidden"
    }
  }]
}))), kn = J(gn, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (e, t) => t.backdrop
})({
  zIndex: -1
}), Me = /* @__PURE__ */ l.forwardRef(function(t, r) {
  const o = oe({
    name: "MuiModal",
    props: t
  }), {
    BackdropComponent: i = kn,
    BackdropProps: s,
    classes: a,
    className: c,
    closeAfterTransition: d = !1,
    children: h,
    container: v,
    component: y,
    components: T = {},
    componentsProps: m = {},
    disableAutoFocus: b = !1,
    disableEnforceFocus: k = !1,
    disableEscapeKeyDown: R = !1,
    disablePortal: x = !1,
    disableRestoreFocus: P = !1,
    disableScrollLock: u = !1,
    hideBackdrop: E = !1,
    keepMounted: C = !1,
    onBackdropClick: w,
    onClose: N,
    onTransitionEnter: O,
    onTransitionExited: S,
    open: M,
    slotProps: D = {},
    slots: A = {},
    // eslint-disable-next-line react/prop-types
    theme: V,
    ...g
  } = o, p = {
    ...o,
    closeAfterTransition: d,
    disableAutoFocus: b,
    disableEnforceFocus: k,
    disableEscapeKeyDown: R,
    disablePortal: x,
    disableRestoreFocus: P,
    disableScrollLock: u,
    hideBackdrop: E,
    keepMounted: C
  }, {
    getRootProps: f,
    getBackdropProps: I,
    getTransitionProps: $e,
    portalRef: De,
    isTopModal: Ae,
    exited: ce,
    hasTransition: le
  } = xn({
    ...p,
    rootRef: r
  }), q = {
    ...p,
    exited: ce
  }, F = wn(q), _ = {};
  if (h.props.tabIndex === void 0 && (_.tabIndex = "-1"), le) {
    const {
      onEnter: j,
      onExited: K
    } = $e();
    _.onEnter = j, _.onExited = K;
  }
  const ue = {
    ...g,
    slots: {
      root: T.Root,
      backdrop: T.Backdrop,
      ...A
    },
    slotProps: {
      ...m,
      ...D
    }
  }, [je, Be] = X("root", {
    elementType: Nn,
    externalForwardedProps: ue,
    getSlotProps: f,
    additionalProps: {
      ref: r,
      as: y
    },
    ownerState: q,
    className: H(c, F == null ? void 0 : F.root, !q.open && q.exited && (F == null ? void 0 : F.hidden))
  }), [Le, de] = X("backdrop", {
    elementType: i,
    externalForwardedProps: ue,
    additionalProps: s,
    getSlotProps: (j) => I({
      ...j,
      onClick: (K) => {
        w && w(K), j != null && j.onClick && j.onClick(K);
      }
    }),
    className: H(s == null ? void 0 : s.className, F == null ? void 0 : F.backdrop),
    ownerState: q
  }), Ve = L(s == null ? void 0 : s.ref, de.ref);
  return !C && !M && (!le || ce) ? null : /* @__PURE__ */ $(bn, {
    ref: De,
    container: v,
    disablePortal: x,
    children: /* @__PURE__ */ Re(je, {
      ...Be,
      children: [!E && i ? /* @__PURE__ */ $(Le, {
        ...de,
        ref: Ve
      }) : null, /* @__PURE__ */ $(Y, {
        disableEnforceFocus: k,
        disableAutoFocus: b,
        disableRestoreFocus: P,
        isEnabled: Ae,
        open: M,
        children: /* @__PURE__ */ l.cloneElement(h, _)
      })]
    })
  });
});
process.env.NODE_ENV !== "production" && (Me.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * A backdrop component. This prop enables custom backdrop rendering.
   * @deprecated Use `slots.backdrop` instead. While this prop currently works, it will be removed in the next major version.
   * Use the `slots.backdrop` prop to make your application ready for the next version of Material UI.
   * @default styled(Backdrop, {
   *   name: 'MuiModal',
   *   slot: 'Backdrop',
   *   overridesResolver: (props, styles) => {
   *     return styles.backdrop;
   *   },
   * })({
   *   zIndex: -1,
   * })
   */
  BackdropComponent: n.elementType,
  /**
   * Props applied to the [`Backdrop`](https://mui.com/material-ui/api/backdrop/) element.
   * @deprecated Use `slotProps.backdrop` instead.
   */
  BackdropProps: n.object,
  /**
   * A single child content element.
   */
  children: ie.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: n.object,
  /**
   * @ignore
   */
  className: n.string,
  /**
   * When set to true the Modal waits until a nested Transition is completed before closing.
   * @default false
   */
  closeAfterTransition: n.bool,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: n.elementType,
  /**
   * The components used for each slot inside.
   *
   * @deprecated Use the `slots` prop instead. This prop will be removed in v7. See [Migrating from deprecated APIs](https://mui.com/material-ui/migration/migrating-from-deprecated-apis/) for more details.
   *
   * @default {}
   */
  components: n.shape({
    Backdrop: n.elementType,
    Root: n.elementType
  }),
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * @deprecated Use the `slotProps` prop instead. This prop will be removed in v7. See [Migrating from deprecated APIs](https://mui.com/material-ui/migration/migrating-from-deprecated-apis/) for more details.
   *
   * @default {}
   */
  componentsProps: n.shape({
    backdrop: n.oneOfType([n.func, n.object]),
    root: n.oneOfType([n.func, n.object])
  }),
  /**
   * An HTML element or function that returns one.
   * The `container` will have the portal children appended to it.
   *
   * You can also provide a callback, which is called in a React layout effect.
   * This lets you set the container from a ref, and also makes server-side rendering possible.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: n.oneOfType([ke, n.func]),
  /**
   * If `true`, the modal will not automatically shift focus to itself when it opens, and
   * replace it to the last focused element when it closes.
   * This also works correctly with any modal children that have the `disableAutoFocus` prop.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableAutoFocus: n.bool,
  /**
   * If `true`, the modal will not prevent focus from leaving the modal while open.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableEnforceFocus: n.bool,
  /**
   * If `true`, hitting escape will not fire the `onClose` callback.
   * @default false
   */
  disableEscapeKeyDown: n.bool,
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: n.bool,
  /**
   * If `true`, the modal will not restore focus to previously focused element once
   * modal is hidden or unmounted.
   * @default false
   */
  disableRestoreFocus: n.bool,
  /**
   * Disable the scroll lock behavior.
   * @default false
   */
  disableScrollLock: n.bool,
  /**
   * If `true`, the backdrop is not rendered.
   * @default false
   */
  hideBackdrop: n.bool,
  /**
   * Always keep the children in the DOM.
   * This prop can be useful in SEO situation or
   * when you want to maximize the responsiveness of the Modal.
   * @default false
   */
  keepMounted: n.bool,
  /**
   * Callback fired when the backdrop is clicked.
   * @deprecated Use the `onClose` prop with the `reason` argument to handle the `backdropClick` events.
   */
  onBackdropClick: n.func,
  /**
   * Callback fired when the component requests to be closed.
   * The `reason` parameter can optionally be used to control the response to `onClose`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.
   */
  onClose: n.func,
  /**
   * A function called when a transition enters.
   */
  onTransitionEnter: n.func,
  /**
   * A function called when a transition has exited.
   */
  onTransitionExited: n.func,
  /**
   * If `true`, the component is shown.
   */
  open: n.bool.isRequired,
  /**
   * The props used for each slot inside the Modal.
   * @default {}
   */
  slotProps: n.shape({
    backdrop: n.oneOfType([n.func, n.object]),
    root: n.oneOfType([n.func, n.object])
  }),
  /**
   * The components used for each slot inside the Modal.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: n.shape({
    backdrop: n.elementType,
    root: n.elementType
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: n.oneOfType([n.arrayOf(n.oneOfType([n.func, n.object, n.bool])), n.func, n.object])
});
const jn = Me;
function Cn(e) {
  return ne("MuiPaper", e);
}
te("MuiPaper", ["root", "rounded", "outlined", "elevation", "elevation0", "elevation1", "elevation2", "elevation3", "elevation4", "elevation5", "elevation6", "elevation7", "elevation8", "elevation9", "elevation10", "elevation11", "elevation12", "elevation13", "elevation14", "elevation15", "elevation16", "elevation17", "elevation18", "elevation19", "elevation20", "elevation21", "elevation22", "elevation23", "elevation24"]);
const On = (e) => {
  const {
    square: t,
    elevation: r,
    variant: o,
    classes: i
  } = e, s = {
    root: ["root", o, !t && "rounded", o === "elevation" && `elevation${r}`]
  };
  return re(s, Cn, i);
}, Sn = J("div", {
  name: "MuiPaper",
  slot: "Root",
  overridesResolver: (e, t) => {
    const {
      ownerState: r
    } = e;
    return [t.root, t[r.variant], !r.square && t.rounded, r.variant === "elevation" && t[`elevation${r.elevation}`]];
  }
})(Te(({
  theme: e
}) => ({
  backgroundColor: (e.vars || e).palette.background.paper,
  color: (e.vars || e).palette.text.primary,
  transition: e.transitions.create("box-shadow"),
  variants: [{
    props: ({
      ownerState: t
    }) => !t.square,
    style: {
      borderRadius: e.shape.borderRadius
    }
  }, {
    props: {
      variant: "outlined"
    },
    style: {
      border: `1px solid ${(e.vars || e).palette.divider}`
    }
  }, {
    props: {
      variant: "elevation"
    },
    style: {
      boxShadow: "var(--Paper-shadow)",
      backgroundImage: "var(--Paper-overlay)"
    }
  }]
}))), Fe = /* @__PURE__ */ l.forwardRef(function(t, r) {
  var m;
  const o = oe({
    props: t,
    name: "MuiPaper"
  }), i = xe(), {
    className: s,
    component: a = "div",
    elevation: c = 1,
    square: d = !1,
    variant: h = "elevation",
    ...v
  } = o, y = {
    ...o,
    component: a,
    elevation: c,
    square: d,
    variant: h
  }, T = On(y);
  return process.env.NODE_ENV !== "production" && i.shadows[c] === void 0 && console.error([`MUI: The elevation provided <Paper elevation={${c}}> is not available in the theme.`, `Please make sure that \`theme.shadows[${c}]\` is defined.`].join(`
`)), /* @__PURE__ */ $(Sn, {
    as: a,
    ownerState: y,
    className: H(T.root, s),
    ref: r,
    ...v,
    style: {
      ...h === "elevation" && {
        "--Paper-shadow": (i.vars || i).shadows[c],
        ...i.vars && {
          "--Paper-overlay": (m = i.vars.overlays) == null ? void 0 : m[c]
        },
        ...!i.vars && i.palette.mode === "dark" && {
          "--Paper-overlay": `linear-gradient(${pe("#fff", fe(c))}, ${pe("#fff", fe(c))})`
        }
      },
      ...v.style
    }
  });
});
process.env.NODE_ENV !== "production" && (Fe.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The content of the component.
   */
  children: n.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: n.object,
  /**
   * @ignore
   */
  className: n.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: n.elementType,
  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It accepts values between 0 and 24 inclusive.
   * @default 1
   */
  elevation: se(en, (e) => {
    const {
      elevation: t,
      variant: r
    } = e;
    return t > 0 && r === "outlined" ? new Error(`MUI: Combining \`elevation={${t}}\` with \`variant="${r}"\` has no effect. Either use \`elevation={0}\` or use a different \`variant\`.`) : null;
  }),
  /**
   * If `true`, rounded corners are disabled.
   * @default false
   */
  square: n.bool,
  /**
   * @ignore
   */
  style: n.object,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: n.oneOfType([n.arrayOf(n.oneOfType([n.func, n.object, n.bool])), n.func, n.object]),
  /**
   * The variant to use.
   * @default 'elevation'
   */
  variant: n.oneOfType([n.oneOf(["elevation", "outlined"]), n.string])
});
const Bn = Fe;
export {
  gn as B,
  hn as F,
  ke as H,
  jn as M,
  Bn as P,
  B as a,
  ae as b,
  X as c,
  ye as d,
  ie as e,
  Qe as g,
  en as i,
  z as o,
  An as r,
  Z as u
};
